import Layout from "../components/layout";
import JobListView from '../components/Jobs/jobListView';

const MyJobs = () => {  
  return (
    <>
      <div className="wizard-layout__background">
        <div className="wizard-layout__bg-magenta" />
        <div className="wizard-layout__bg-blue" />
        <div className="wizard-layout__bg-purple" />
      </div>
      <Layout  description="Remote Inclusive - My Jobs" pageTitle="Remote Inclusive - My Jobs">
        <JobListView />
      </Layout>
    </>
  );
}

export default MyJobs;