import { collection, getDocs, getFirestore, where, query } from "firebase/firestore"; 
import firebaseApp from "../../utils/firebaseApp";
import { getAuth, onAuthStateChanged  } from 'firebase/auth';
import Job from "../../Entities/Job";
import { useEffect, useState, useContext } from 'react';
import Card from '@mui/material/Card';
import Button from "@mui/material/Button";
import { StateContext, DispatchContext } from "../../utils/providers";
import { navigate } from "gatsby";
import Chip from '@mui/material/Chip';
import { RocketIcon, PlugDisconnectedIcon } from "@fluentui/react-icons-mdl2";

import './jobListView.scss';

const JobListView = () => {
  const dispatch = useContext(DispatchContext);
  const [jobs, setJobs] = useState<Array<Job>>();
  const state = useContext(StateContext);
  const jobsInCart = state.job.jobsInCart;

  const navigateToJob = (job: Job) => {
    dispatch({ type: 'SET_JOB', job: job});
    navigate('/post');
  };

  useEffect(() => {
    const auth = getAuth(firebaseApp);
    onAuthStateChanged(auth, async (user) => {
      const db = getFirestore(firebaseApp);
      const jobCollection = collection(db, 'jobs');
      if (user?.uid) {
        const q = query(jobCollection, where("userId", "==", user.uid));
        const jobList: Array<Job> = [];
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          const jobData = doc.data();
          const job: Job = { ...jobData, jobTitle: jobData.jobTitle, jobId: jobData.jobId };
          jobList.push(job);
        });
        setJobs(jobList);
      }
    });
  }, []);

  const addJobToCart = (job: Job) => {
    dispatch({ type: 'ADD_JOB_TO_CART', job: job});
  };

  const removeJobFromCart = (job: Job) => {
    dispatch({ type: 'REMOVE_JOB_FROM_CART', jobId: job.jobId});
  };

  const addJobClick = () => {
    navigate('/post');
  };

  return (
    <div className="job-list">
      <div className="job-list__add-job">
        <Button variant="contained" onClick={addJobClick}>Add Job</Button>
      </div>
      <div className="job-list__jobs">
        {jobs && jobs.map(element => {
          const jobInCart = jobsInCart?.find((job) => { return job.jobId === element.jobId });
          const currentDate = new Date();
          const jobActive = element.createDate && element.createDate.toDate() < currentDate;
          const jobExpired = element.expirationDate && element.expirationDate.toDate() < currentDate;
          const hasPayRange = element.payRangeStart !== undefined && element.payRangeEnd !== undefined;
          const payRangeGreaterThanZero = element.payRangeStart && element.payRangeStart !== 0 && element.payRangeEnd !== 0;
          const atLeast50CharsDEIPolicy = element.deiPolicy !== undefined && element.deiPolicy.length > 50;
          const hasTitle = element.jobTitle !== undefined && element.jobTitle.length > 2;
          const hasCompany = element.company !== undefined && element.company.length > 2;
          const hasApplicationLink = element.applyLink !== undefined && element.applyLink.length > 4;
          const complete = hasPayRange && payRangeGreaterThanZero && atLeast50CharsDEIPolicy && hasTitle &&
            hasCompany && hasApplicationLink;
          return (
            <Card className="job-list__card" key={`job-${element.jobId}`}>
              <div className="job-list__logo"><img src={element.logoImageUrl} alt={`${element.company} logo`} /></div>
              <div className="job-list__job">                 
                <div className="job-list__job-title">{element.jobTitle}</div>
                <div className="job-list__job-details">
                  {element.createDate && (
                    <span>Active: {element.createDate.toDate().toDateString()}</span>
                  )}
                  {element.expirationDate && (
                    <span>Expires: {element.expirationDate.toDate().toDateString()}</span>
                  )}
                </div>
              </div>
              <div className="job-list__actions">
                {!jobExpired && <Button variant="contained" onClick={() => { navigateToJob(element) }}>Edit</Button>}
                {!complete && !jobActive && !jobExpired && (
                  "Incomplete Post"
                )}
                {jobActive && (<Chip icon={<RocketIcon />} label="Post Active" />)}
                {jobExpired && (<Chip icon={<PlugDisconnectedIcon />} label="Post Expired" />)}
                {complete && !jobActive && !jobExpired && jobInCart && (
                  <Button variant="contained" onClick={() => { removeJobFromCart(element) }}>Remove From Cart</Button>
                )}
                {complete && !jobActive && !jobExpired && !jobInCart && (
                  <Button variant="contained" onClick={() => { addJobToCart(element) }}>Add to Cart</Button>
                )}
              </div>
            </Card>
          )
        })}
      </div>
    </div>
  )
}

export default JobListView;